import React, { useState, useEffect } from "react"
import "./index.css"
import { Link } from "gatsby"
import FirebaseApp from "./../../config/firebase"
import { Container, Row, Col, Form, Spinner } from "react-bootstrap"
import Tab from "react-bootstrap/Tab"
import Tabs from "react-bootstrap/Tabs"
import { AiFillCheckCircle } from "react-icons/ai"
import { navigate } from "gatsby" //import navigate from gatsby
import Logo from "./../../images/colorlogo-01.png"

let database = FirebaseApp.database().ref("/")

export default function Signup(props) {
  var [name, setName] = useState("")
  var [fatherName, setFather] = useState("")
  var [email, setEmail] = useState("")
  var [password, setpassword] = useState("")
  var [nicNumber, setNic] = useState("")
  var [mobileNum, setMobileNum] = useState("")
  var [course, setcourse] = useState("")
  var [qualification, setqualification] = useState("")
  var [MobileAppCretificate, setMobileAppCretificate] = useState("")
  var [GrapichCretificate, setGrapichCretificate] = useState("")
  var [url, seturl] = useState("")
  var [err, seterr] = useState("")
  var [disabled, setdisabled] = useState(false)
  var [checked, setChecked] = useState(false)
  const [isLoader, setIsLoader] = useState(false)
  const [systemToken, setSystemToken] = useState("")
  const [isAdmission, setAdmition] = useState(true)
  const [batches, setBatches] = useState([])
  const [onlineBatches, setonlineBatches] = useState(null)
  const [gender, setGender] = useState("")
  const [discount, setDiscount] = useState({})

  useEffect(() => {
    ;(async () => {
      let batche = []
      let onlineBatche = []
      await database.child("Admission").once("value", res => {
        let data = res.val()
        setAdmition(data.admission)
      })
      await database.child("discount/").once("value", res => {
        var data = res.val()
        setDiscount(data)
        // this.setState({
        //   disCountForMales: data.disCountForMales,
        //   disCountForFemales: data.disCountForFemales,
        //   checked:data.checked
        // });
      })
      database.child("batches/").on("child_added", res => {
        var data = res.val()
        data.id = res.key
        batche.push(data)
      })
      database.child("onlineBatches/").on("child_added", res => {
        var onlineData = res.val()
        onlineData.id = res.key
        onlineBatche.push(onlineData)
      })

      setTimeout(() => {
        setBatches(batche[batche.length - 1])
        setonlineBatches(onlineBatche[onlineBatche.length - 1])
      }, 5000)
    })()
  }, [])

  const getImageURL = async (e, value) => {
    if (e.target.files.length !== 0) {
      let imageName = e.target.files[0].name
      var imageType = e.target.files[0].type
      let ref = FirebaseApp.storage()
        .ref("/")
        .child(`studentsImages/${imageName}`)
      if (imageType === "image/png" || imageType === "image/jpeg") {
        setdisabled(true)
        seterr("")
        await ref.put(e.target.files[0])
        ref.getDownloadURL().then(url => {
          if (value === "profile") {
            seturl(url)
            setdisabled(false)
          }
          if (value === "Mobile App Development") {
            setMobileAppCretificate(url)
            setdisabled(false)
          }
          if (value === "Video Editing") {
            setGrapichCretificate(url)
            setdisabled(false)
          }
        })
      } else {
        seterr("Please select file in jpeg or png format")
      }
    } else {
      setdisabled(false)
      seterr("")
    }
  }

  const _applyNow = async () => {
    var flag = false
    if (name === "") {
      seterr("Please Enter your name")
      setIsLoader(false)
    } else if (fatherName === "") {
      seterr("Please enter your father's name")
      setIsLoader(false)
    } else if (email === "") {
      seterr("Please enter your email")
      setIsLoader(false)
    } else if (password === "") {
      seterr("Please enter your password")
      setIsLoader(false)
    } else if (nicNumber === "") {
      seterr("Please enter your CNIC number")
      setIsLoader(false)
    } else if (nicNumber.length < 13) {
      seterr("Please 14 character in nic number")
      setIsLoader(false)
    } else if (mobileNum === "") {
      seterr("Please enter your mobile number")
      setIsLoader(false)
    } else if (course === "" || course === "Select Courses") {
      seterr("Please select any one course")
      setIsLoader(false)
    } else if (
      course === "Mobile App Development" &&
      MobileAppCretificate === ""
    ) {
      seterr("Required web developement certification for apply in this course")
      setIsLoader(false)
    } else if (course === "Video Editing" && GrapichCretificate === "") {
      seterr("Required Graphic design cretificate for apply in this course")
      setIsLoader(false)
    } else if (mobileNum.length < 11) {
      seterr("Please 11 character in mobile number")
      setIsLoader(false)
    } else if (
      qualification === "" ||
      qualification === "Select Qualification"
    ) {
      seterr("Please select your qualification")
      setIsLoader(false)
    } else if (gender === "" || gender === "Select Gender") {
      seterr("Please select your Gender")
      setIsLoader(false)
    } else if (url === "") {
      seterr("Please upload your passport size photo")
      setIsLoader(false)
    } else if (checked === false) {
      seterr("You must have to select checkbox")
      setIsLoader(false)
    } else {
      for (var i = 0; i < batches.courses.length; i++) {
        if (course === batches.courses[i]) {
          flag = true
          var obj = {
            name: name,
            fatherName: fatherName,
            email: email,
            nicNumber: nicNumber,
            qualification: qualification,
            url: url,
            approved: false,
            mobileNum: mobileNum,
            course,
            systemToken,
            courseType: "onsite",
            batch: batches.batch_name,
            gender,
          }
          if (GrapichCretificate !== "") {
            obj.certificate = GrapichCretificate
          } else if (MobileAppCretificate !== "") {
            obj.certificate = MobileAppCretificate
          }
          if (course === "Freelancing") {
            obj.oneTimeFees = true
            obj.totalFees = 5000
            obj.addmissionFees = 5000
            obj.duration = false
          } else if (course === "Social Media Marketing") {
            obj.oneTimeFees = true
            obj.totalFees = 2999
            obj.addmissionFees = 2999
            obj.duration = false
          } else if (course === "Youtube Mastery") {
            obj.oneTimeFees = true
            obj.totalFees = 2399
            obj.addmissionFees = 2399
            obj.duration = false
          } else if (course === "Web Development & Web Designing") {
            obj.oneTimeFees = false
            obj.totalFees = 23000
            if (gender === "Female") {
              if (discount.checkedforFemales) {
                obj.addmissionFees =
                  batches.addmissionFees -
                  (batches.addmissionFees * discount.disCountForFemales) / 100
              } else {
                obj.addmissionFees = batches.addmissionFees
              }
            } else if (gender === "Male") {
              if (discount.checkedforMales) {
                obj.addmissionFees =
                  batches.addmissionFees -
                  (batches.addmissionFees * discount.disCountForMales) / 100
              } else {
                obj.addmissionFees = batches.addmissionFees
              }
            }
            obj.monthlyFees = batches.monthlyFees
            obj.duration = [
              {
                month: "First Month",
                paid: true,
              },
              {
                month: "Second Month",
                paid: false,
              },
              {
                month: "Third Month",
                paid: false,
              },
              {
                month: "Fourth Month",
                paid: false,
              },
              {
                month: "Fifth Month",
                paid: false,
              },
              {
                month: "Sixth Month",
                paid: false,
              },
            ]
          } else if (course === "Mobile App Development") {
            obj.oneTimeFees = false
            obj.totalFees = 14000
            obj.addmissionFees = 8000
            obj.monthlyFees = 3000
            obj.duration = [
              {
                month: "First Month",
                paid: true,
              },
              {
                month: "Second Month",
                paid: false,
              },
              {
                month: "Third Month",
                paid: false,
              },
            ]
          } else {
            obj.oneTimeFees = false
            obj.totalFees = 14000
            if (gender === "Female") {
              if (discount.checkedforFemales) {
                obj.addmissionFees =
                  batches.addmissionFees -
                  (batches.addmissionFees * discount.disCountForFemales) / 100
              } else {
                obj.addmissionFees = batches.addmissionFees
              }
            } else if (gender === "Male") {
              if (discount.checkedforMales) {
                obj.addmissionFees =
                  batches.addmissionFees -
                  (batches.addmissionFees * discount.disCountForMales) / 100
              } else {
                obj.addmissionFees = batches.addmissionFees
              }
            }
            obj.monthlyFees = batches.monthlyFees
            obj.duration = [
              {
                month: "First Month",
                paid: true,
              },
              {
                month: "Second Month",
                paid: false,
              },
              {
                month: "Third Month",
                paid: false,
              },
            ]
          }
          setIsLoader(true)
          await FirebaseApp.auth()
            .createUserWithEmailAndPassword(email, password)
            .then(async res => {
              obj.uid = res.user.uid
              database.child("Registration/" + `${res.user.uid}` + "/").set(obj)
              localStorage.setItem("user", JSON.stringify(obj))
              seterr("")
              setName("")
              setFather("")
              setEmail("")
              seterr("")
              setNic("")
              seturl("")
              setqualification("")
              await FirebaseApp.auth()
                .currentUser.sendEmailVerification()
                .then(res => {
                  setIsLoader(false)
                  console.log(res)
                  navigate("/emailVerification")
                })
                .catch(err => {
                  seterr(err.message)
                  setIsLoader(false)
                })
            })
            .catch(err => {
              seterr(err.message)
              setIsLoader(false)
            })
        }
      }
      if (flag === false) {
        seterr("this course is not available at this time")
      }
    }
  }

  // ONLINE REGISTRATION
  const applyNowOnline = async () => {
    var flag = false
    if (name === "") {
      seterr("Please Enter your name")
      setIsLoader(false)
    } else if (fatherName === "") {
      seterr("Please enter your father's name")
      setIsLoader(false)
    } else if (email === "") {
      seterr("Please enter your email")
      setIsLoader(false)
    } else if (password === "") {
      seterr("Please enter your password")
      setIsLoader(false)
    } else if (nicNumber === "") {
      seterr("Please enter your CNIC number")
      setIsLoader(false)
    } else if (nicNumber.length < 13) {
      seterr("Please 14 character in nic number")
      setIsLoader(false)
    } else if (mobileNum === "") {
      seterr("Please enter your mobile number")
      setIsLoader(false)
    } else if (course === "" || course === "Select Courses") {
      seterr("Please select any one course")
      setIsLoader(false)
    } else if (mobileNum.length < 11) {
      seterr("Please 11 character in mobile number")
      setIsLoader(false)
    } else if (
      qualification === "" ||
      qualification === "Select Qualification"
    ) {
      seterr("Please select your qualification")
      setIsLoader(false)
    } else if (gender === "" || gender === "Select Gender") {
      seterr("Please select your Gender")
      setIsLoader(false)
    } else if (url === "") {
      seterr("Please upload your passport size photo")
      setIsLoader(false)
    } else if (checked === false) {
      seterr("You must have to select checkbox")
      setIsLoader(false)
    } else {
      var obj = {
        name: name,
        fatherName: fatherName,
        email: email,
        nicNumber: nicNumber,
        qualification: qualification,
        url: url,
        approved: false,
        mobileNum: mobileNum,
        course,
        registrationType: "online",
        batch: onlineBatches.batch_name,
        gender,
        oneTimeFees: true,
        totalFees: onlineBatches.fees,
        addmissionFees: onlineBatches.fees,
        duration: false,
      }

      setIsLoader(true)
      await FirebaseApp.auth()
        .createUserWithEmailAndPassword(email, password)
        .then(async res => {
          obj.uid = res.user.uid
          database.child("Registration/" + `${res.user.uid}` + "/").set(obj)
          localStorage.setItem("user", JSON.stringify(obj))
          seterr("")
          setName("")
          setFather("")
          setEmail("")
          seterr("")
          setNic("")
          seturl("")
          setqualification("")
          await FirebaseApp.auth()
            .currentUser.sendEmailVerification()
            .then(res => {
              setIsLoader(false)
              console.log(res)
              navigate("/emailVerification")
            })
            .catch(err => {
              seterr(err.message)
              setIsLoader(false)
            })
        })
        .catch(err => {
          seterr(err.message)
          setIsLoader(false)
        })
      // for (var i = 0; i < batches.courses.length; i++) {
      //   if (course === batches.courses[i]) {
      //     flag = true
      //     var obj = {
      //       name: name,
      //       fatherName: fatherName,
      //       email: email,
      //       nicNumber: nicNumber,
      //       qualification: qualification,
      //       url: url,
      //       approved: false,
      //       mobileNum: mobileNum,
      //       course,
      //       systemToken,
      //       courseType: "onsite",
      //       batch: batches.batch_name,
      //       gender,
      //     }
      //     if (GrapichCretificate !== "") {
      //       obj.certificate = GrapichCretificate
      //     } else if (MobileAppCretificate !== "") {
      //       obj.certificate = MobileAppCretificate
      //     }
      //     if (course === "Freelancing") {
      //       obj.oneTimeFees = true
      //       obj.totalFees = 5000
      //       obj.addmissionFees = 5000
      //       obj.duration = false
      //     } else if (course === "Social Media Marketing") {
      //       obj.oneTimeFees = true
      //       obj.totalFees = 2999
      //       obj.addmissionFees = 2999
      //       obj.duration = false
      //     } else if (course === "Youtube Mastery") {
      //       obj.oneTimeFees = true
      //       obj.totalFees = 2399
      //       obj.addmissionFees = 2399
      //       obj.duration = false
      //     } else if (course === "Web Development & Web Designing") {
      //       obj.oneTimeFees = false
      //       obj.totalFees = 23000
      //       if (gender === "Female") {
      //         if (discount.checkedforFemales) {
      //           obj.addmissionFees =
      //             batches.addmissionFees -
      //             (batches.addmissionFees * discount.disCountForFemales) / 100
      //         } else {
      //           obj.addmissionFees = batches.addmissionFees
      //         }
      //       } else if (gender === "Male") {
      //         if (discount.checkedforMales) {
      //           obj.addmissionFees =
      //             batches.addmissionFees -
      //             (batches.addmissionFees * discount.disCountForMales) / 100
      //         } else {
      //           obj.addmissionFees = batches.addmissionFees
      //         }
      //       }
      //       obj.monthlyFees = batches.monthlyFees
      //       obj.duration = [
      //         {
      //           month: "First Month",
      //           paid: true,
      //         },
      //         {
      //           month: "Second Month",
      //           paid: false,
      //         },
      //         {
      //           month: "Third Month",
      //           paid: false,
      //         },
      //         {
      //           month: "Fourth Month",
      //           paid: false,
      //         },
      //         {
      //           month: "Fifth Month",
      //           paid: false,
      //         },
      //         {
      //           month: "Sixth Month",
      //           paid: false,
      //         },
      //       ]
      //     } else {
      //       obj.oneTimeFees = false
      //       obj.totalFees = 14000
      //       if (gender === "Female") {
      //         if (discount.checkedforFemales) {
      //           obj.addmissionFees =
      //             batches.addmissionFees -
      //             (batches.addmissionFees * discount.disCountForFemales) / 100
      //         } else {
      //           obj.addmissionFees = batches.addmissionFees
      //         }
      //       } else if (gender === "Male") {
      //         if (discount.checkedforMales) {
      //           obj.addmissionFees =
      //             batches.addmissionFees -
      //             (batches.addmissionFees * discount.disCountForMales) / 100
      //         } else {
      //           obj.addmissionFees = batches.addmissionFees
      //         }
      //       }
      //       obj.monthlyFees = batches.monthlyFees
      //       obj.duration = [
      //         {
      //           month: "First Month",
      //           paid: true,
      //         },
      //         {
      //           month: "Second Month",
      //           paid: false,
      //         },
      //         {
      //           month: "Third Month",
      //           paid: false,
      //         },
      //       ]
      //     }
      //     setIsLoader(true)
      //     await FirebaseApp.auth()
      //       .createUserWithEmailAndPassword(email, password)
      //       .then(async res => {
      //         obj.uid = res.user.uid
      //         database.child("Registration/" + `${res.user.uid}` + "/").set(obj)
      //         localStorage.setItem("user", JSON.stringify(obj))
      //         seterr("")
      //         setName("")
      //         setFather("")
      //         setEmail("")
      //         seterr("")
      //         setNic("")
      //         seturl("")
      //         setqualification("")
      //         await FirebaseApp.auth()
      //           .currentUser.sendEmailVerification()
      //           .then(res => {
      //             setIsLoader(false)
      //             console.log(res)
      //             navigate("/emailVerification")
      //           })
      //           .catch(err => {
      //             seterr(err.message)
      //             setIsLoader(false)
      //           })
      //       })
      //       .catch(err => {
      //         seterr(err.message)
      //         setIsLoader(false)
      //       })
      //   }
      // }
      // if (flag === false) {
      //   seterr("this course is not available at this time")
      // }
    }
  }
  // SET VALID MOIBLE NUMBER
  const setNumber = v => {
    if (v >= 0 && v.length <= 11) {
      setMobileNum(v)
      seterr("")
    }
  }
  const setCnic = v => {
    if (v >= 0 && v.length <= 13) {
      setNic(v)
      seterr("  ")
    }
  }
  return (
    <div
      style={{
        backgroundColor: "#fae6ff",
        display: "flex",
        flex: 1,
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Container>
        <Row className="justify-content-center">
          <Col lg={6}>
            <div className="Signup">
              <p className="_formTitle">Student Sign Up</p>
              <img
                src={Logo}
                className="logo"
              />
              <Tabs
                defaultActiveKey="physical"
                id="justify-tab-example"
                className="mb-3"
                justify
                style={{ width: "80%", marginLeft: "10%" }}
              >
                <Tab
                  tabClassName="tabStyle"
                  eventKey="physical"
                  title="Physical"
                >
                  <div className="_image_caption">
                    Apply form here for physical classes.
                  </div>
                  <div className="_inputDiv">
                    <input
                      type="text"
                      className="_input"
                      placeholder="Name"
                      onChange={e => {
                        seterr("")
                        setName(e.target.value)
                      }}
                      name="name"
                    />
                  </div>
                  <div className="_inputDiv">
                    <input
                      type="text"
                      className="_input"
                      value={fatherName}
                      placeholder="Father Name"
                      onChange={e => {
                        seterr("")
                        setFather(e.target.value)
                      }}
                      name="fatherName"
                    />
                  </div>
                  <div className="_inputDiv">
                    <input
                      type="text"
                      className="_input"
                      placeholder="Email"
                      onChange={e => {
                        seterr("")
                        setEmail(e.target.value)
                      }}
                      name="email"
                    />
                  </div>
                  <div className="_inputDiv">
                    <input
                      type="password"
                      className="_input"
                      placeholder="Password"
                      onChange={e => {
                        seterr("")
                        setpassword(e.target.value)
                      }}
                      name="password"
                    />
                  </div>
                  <div className="_inputDiv">
                    <input
                      type="number"
                      className="_input"
                      value={nicNumber}
                      placeholder="CNIC Number"
                      onChange={e => {
                        setCnic(e.target.value)
                        seterr("")
                      }}
                      name="cnicnumber"
                    />
                  </div>
                  <div className="_inputDiv">
                    <input
                      type="file"
                      className="_input"
                      onChange={getImageURL}
                      style={{ border: "2px solid green" }}
                    />
                  </div>
                  <div className="_inputDiv">
                    <input
                      type="number"
                      className="_input"
                      value={mobileNum}
                      placeholder="Mobile Number"
                      onChange={e => {
                        seterr("")
                        setNumber(e.target.value)
                      }}
                      name="mobileNumber"
                    />
                  </div>
                  <select
                    className="_select_Course"
                    value={course}
                    onChange={e => {
                      setcourse(e.target.value)
                      seterr("")
                    }}
                  >
                    <option selected style={{ color: "grey" }}>
                      Select Courses
                    </option>
                    {batches.length !== 0 &&
                      batches.courses.map((val, i) => {
                        return <option>{val}</option>
                      })}
                    {/* <option>Mobile App Development</option>
                    <option>E-commerce Course</option>
                    <option>Web Development & Web Designing</option>
                    <option>Freelancing</option>
                    <option>Social Media Marketing</option>
                    <option>Youtube Mastery</option>
                    <option>Digital Marketing</option>
                    <option>Graphic Design</option>
                    <option>Wordpress</option> */}
                  </select>
                  {course === "Mobile App Development" ? (
                    <Form.Group>
                      <Form.File
                        id="exampleFormControlFile1"
                        label={
                          MobileAppCretificate === "" ? (
                            "upload your web development certificate"
                          ) : (
                            <div className="_upload_pic_css">
                              {" "}
                              <div>uploaded certification successfullly </div>
                              <AiFillCheckCircle />{" "}
                            </div>
                          )
                        }
                        className="_upload"
                        onChange={e => getImageURL(e, "Mobile App Development")}
                      />
                    </Form.Group>
                  ) : null}

                  {course === "Video Editing" ? (
                    <Form.Group>
                      <Form.File
                        id="exampleFormControlFile2"
                        label={
                          GrapichCretificate === "" ? (
                            "upload your graphic designing certificate"
                          ) : (
                            <div className="_upload_pic_css">
                              {" "}
                              <div>uploaded certification successfullly </div>
                              <AiFillCheckCircle />{" "}
                            </div>
                          )
                        }
                        className="_upload"
                        onChange={e => getImageURL(e, "Video Editing")}
                      />
                    </Form.Group>
                  ) : null}

                  <select
                    className="_select_Course"
                    value={qualification}
                    onChange={e => {
                      setqualification(e.target.value)
                      seterr("")
                    }}
                  >
                    <option selected style={{ color: "grey" }}>
                      Select Qualification
                    </option>
                    <option>Matriculation</option>
                    <option>Intermediate</option>
                    <option>Graduate</option>
                  </select>
                  <select
                    className="_select_Course"
                    value={gender}
                    onChange={e => {
                      setGender(e.target.value)
                      seterr("")
                    }}
                  >
                    <option
                      value="Select Gender"
                      selected
                      style={{ color: "grey" }}
                    >
                      Select Gender
                    </option>
                    <option value="Male">Male</option>
                    <option value="Female">Female</option>
                  </select>
                  <div className="_inputDiv">
                    <Form.Group>
                      <Form.File
                        id="exampleFormControlFile3"
                        label={
                          url === "" ? (
                            "Upload Photo"
                          ) : (
                            <div className="_upload_pic_css">
                              {" "}
                              <div>uploaded successfullly </div>
                              <AiFillCheckCircle />{" "}
                            </div>
                          )
                        }
                        className="_upload"
                        onChange={e => getImageURL(e, "profile")}
                      />
                    </Form.Group>
                  </div>

                  {url !== "" ? (
                    <div className="_image_caption" style={{ color: "green" }}>
                      uploaded pic successfully
                    </div>
                  ) : (
                    <div className="_image_caption">
                      Upload your passport size photo with blue background
                    </div>
                  )}
                  <div
                    style={{ textAlign: "left", width: "80%", margin: "auto" }}
                  >
                    <input
                      type="checkbox"
                      onChange={e => setChecked(e.target.checked)}
                    />
                    <span className="_laptopTxt">
                      I have laptop for classes
                    </span>
                  </div>
                  <div className="_err">{err}</div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      flexWrap: "nowrap",
                      justifyContent: "space-around",
                    }}
                  >
                    <div className="_text"> Already a member?</div>
                    <div className="_text">
                      <Link to="/">Sign In</Link>
                    </div>
                  </div>

                  {isAdmission ? (
                    <button
                      className="_btn"
                      onClick={() => _applyNow()}
                      disabled={disabled}
                      style={disabled ? { opacity: 0.5 } : { opacity: 1 }}
                    >
                      {isLoader ? (
                        <Spinner
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />
                      ) : (
                        "Sign Up"
                      )}
                    </button>
                  ) : (
                    <button
                      className="_btn"
                      disabled
                      style={{ opacity: 0.5, width: "90%", fontSize: 10 }}
                    >
                      {isLoader ? (
                        <Spinner
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />
                      ) : (
                        "Admission closed! Wait for next Batch"
                      )}
                    </button>
                  )}
                </Tab>
                <Tab tabClassName="tabStyle" eventKey="online" title="Online">
                  <div className="_image_caption">
                    Apply form here for online classes.
                  </div>
                  <div className="_inputDiv">
                    <input
                      type="text"
                      className="_input"
                      placeholder="Name"
                      onChange={e => {
                        seterr("")
                        setName(e.target.value)
                      }}
                      name="name"
                    />
                  </div>
                  <div className="_inputDiv">
                    <input
                      type="text"
                      className="_input"
                      value={fatherName}
                      placeholder="Father Name"
                      onChange={e => {
                        seterr("")
                        setFather(e.target.value)
                      }}
                      name="fatherName"
                    />
                  </div>
                  <div className="_inputDiv">
                    <input
                      type="text"
                      className="_input"
                      placeholder="Email"
                      onChange={e => {
                        seterr("")
                        setEmail(e.target.value)
                      }}
                      name="email"
                    />
                  </div>
                  <div className="_inputDiv">
                    <input
                      type="password"
                      className="_input"
                      placeholder="Password"
                      onChange={e => {
                        seterr("")
                        setpassword(e.target.value)
                      }}
                      name="password"
                    />
                  </div>
                  <div className="_inputDiv">
                    <input
                      type="number"
                      className="_input"
                      value={nicNumber}
                      placeholder="CNIC Number"
                      onChange={e => {
                        setCnic(e.target.value)
                        seterr("")
                      }}
                      name="cnicnumber"
                    />
                  </div>
                  <div className="_inputDiv">
                    <input
                      type="file"
                      className="_input"
                      onChange={getImageURL}
                      style={{ border: "2px solid green" }}
                    />
                  </div>
                  <div className="_inputDiv">
                    <input
                      type="number"
                      className="_input"
                      value={mobileNum}
                      placeholder="Mobile Number"
                      onChange={e => {
                        seterr("")
                        setNumber(e.target.value)
                      }}
                      name="mobileNumber"
                    />
                  </div>
                  <select
                    className="_select_Course"
                    value={course}
                    onChange={e => {
                      setcourse(e.target.value)
                      seterr("")
                    }}
                  >
                    <option selected style={{ color: "grey" }}>
                      Select Courses
                    </option>
                    {onlineBatches?.courses.map((val, i) => {
                      return <option>{val}</option>
                    })}
                  </select>
                  {course === "Mobile App Development" ? (
                    <Form.Group>
                      <Form.File
                        id="exampleFormControlFile1"
                        label={
                          MobileAppCretificate === "" ? (
                            "upload your web development certificate"
                          ) : (
                            <div className="_upload_pic_css">
                              {" "}
                              <div>uploaded certification successfullly </div>
                              <AiFillCheckCircle />{" "}
                            </div>
                          )
                        }
                        className="_upload"
                        onChange={e => getImageURL(e, "Mobile App Development")}
                      />
                    </Form.Group>
                  ) : null}

                  {course === "Video Editing" ? (
                    <Form.Group>
                      <Form.File
                        id="exampleFormControlFile2"
                        label={
                          GrapichCretificate === "" ? (
                            "upload your graphic designing certificate"
                          ) : (
                            <div className="_upload_pic_css">
                              {" "}
                              <div>uploaded certification successfullly </div>
                              <AiFillCheckCircle />{" "}
                            </div>
                          )
                        }
                        className="_upload"
                        onChange={e => getImageURL(e, "Video Editing")}
                      />
                    </Form.Group>
                  ) : null}

                  <select
                    className="_select_Course"
                    value={qualification}
                    onChange={e => {
                      setqualification(e.target.value)
                      seterr("")
                    }}
                  >
                    <option selected style={{ color: "grey" }}>
                      Select Qualification
                    </option>
                    <option>Matriculation</option>
                    <option>Intermediate</option>
                    <option>Graduate</option>
                  </select>
                  <select
                    className="_select_Course"
                    value={gender}
                    onChange={e => {
                      setGender(e.target.value)
                      seterr("")
                    }}
                  >
                    <option
                      value="Select Gender"
                      selected
                      style={{ color: "grey" }}
                    >
                      Select Gender
                    </option>
                    <option value="Male">Male</option>
                    <option value="Female">Female</option>
                  </select>
                  <div className="_inputDiv">
                    <Form.Group>
                      <Form.File
                        id="exampleFormControlFile3"
                        label={
                          url === "" ? (
                            "Upload Photo"
                          ) : (
                            <div className="_upload_pic_css">
                              {" "}
                              <div>uploaded successfullly </div>
                              <AiFillCheckCircle />{" "}
                            </div>
                          )
                        }
                        className="_upload"
                        onChange={e => getImageURL(e, "profile")}
                      />
                    </Form.Group>
                  </div>

                  {url !== "" ? (
                    <div className="_image_caption" style={{ color: "green" }}>
                      uploaded pic successfully
                    </div>
                  ) : (
                    <div className="_image_caption">
                      Upload your passport size photo with blue background
                    </div>
                  )}
                  <div
                    style={{ textAlign: "left", width: "80%", margin: "auto" }}
                  >
                    <input
                      type="checkbox"
                      onChange={e => setChecked(e.target.checked)}
                    />
                    <span className="_laptopTxt">
                      I have laptop for classes
                    </span>
                  </div>
                  <div className="_err">{err}</div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      flexWrap: "nowrap",
                      justifyContent: "space-around",
                    }}
                  >
                    <div className="_text"> Already a member?</div>
                    <div className="_text">
                      <Link to="/">Sign In</Link>
                    </div>
                  </div>

                  {isAdmission ? (
                    <button
                      className="_btn"
                      onClick={() => applyNowOnline()}
                      disabled={disabled}
                      style={disabled ? { opacity: 0.5 } : { opacity: 1 }}
                    >
                      {isLoader ? (
                        <Spinner
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />
                      ) : (
                        "Sign Up"
                      )}
                    </button>
                  ) : (
                    <button
                      className="_btn"
                      disabled
                      style={{ opacity: 0.5, width: "90%", fontSize: 10 }}
                    >
                      {isLoader ? (
                        <Spinner
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />
                      ) : (
                        "Admission closed! Wait for next Batch"
                      )}
                    </button>
                  )}
                </Tab>
              </Tabs>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  )
}
